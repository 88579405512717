html{
	height:		100%; 
	margin:		0px; 
	padding:0px;
}

body{
	height:		100%; 
	margin:		0px; 
	padding:0px;
	background:#aaa; 
}

/* SCREEN */
@media screen {

	header,
	.layout-main,
	.layout-map,
	.layout-info
	{
		position:absolute;
		padding:0em;
	}


	header{
		z-index:8;
		top:		0em;
		height:		2em;
		left:		0em;
		right:		0em;
	}
	.layout-main{
		top:		2em;
		bottom:		0em;
		left:		0;
		right:		0em;
		overflow: hidden;
	}

	/* INFO = LIST + ITEM */
	.layout-info{
		display: flex;
		flex-direction: column;
		overflow-y: hidden;
	}
}


/* LANDSCAPE */
@media screen and (orientation:landscape){
	.layout-map {
		top:		0em;
		bottom:		0em;
		left:		0em;
		right:     33em;
	}
	.layout-info{
		top:		0em;
		bottom:		0em;
		width:     33em;
		right:      0em;
	}
}

/* PORTRAIT */
@media screen and (orientation:portrait){
	.layout-map {
		top:		0em;
		bottom:	   33em;
		left:		0em;
		right:      0em;
	}
	.layout-info{
		height:	   33em;
		bottom:		0em;
		left:       0em;
		right:      0em;
	}
}

/* PRINT */
@media print {
/*@media (orientation:portrait){*/
	
	.layout-main,
	.layout-map,
	.layout-info
	{
		position:relative;
	}
	.layout-map{
		width:100%;
		min-height: 30em;
	}
}
