div#root {
  height: 100%;
}

#map {
  position: absolute;
  top: 0%; bottom:0%;
  left:0%; right: 0%;
}

.circularProgress {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.displayNone {
  display: none;
}

.flexElem{
  display: flex;
}
.flexColumn {
  display: flex;
  flex-direction: column;
}
.spaceBetween{
  display: flex;
  justify-content: space-between;
}
.spaceBtwCentered{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.spaceBtwFlexEnd{
  display: flex;
  justify-content: space-between;
  align-items:flex-end;
}
.flexCenter{
  display: flex;
  justify-content: center;
  align-items: center;
}
.flexJustCenter{
  display: flex;
  justify-content: center;
}
.flexJustEnd{
  display: flex;
  justify-content: flex-end;
}
.flexAlignCenter{
  display: flex;
  align-items: center;
}
.flexGrow {
  flex-grow: 1;
}
.flexWrap {
  display: flex;
  flex-wrap: wrap;
}
.fullWidth{
  width:100%;
}
.fullHeight {
  height: 100%;
}
.fullSize {
  width:100%;
  height: 100%;
}
.size5em{
  width:5em;
  height:5em;
}
.paddingElem{
  padding: 1em;
}
.marginElem{
  margin: 1em;
}
.marginTop{
  margin-top: 1em;
}
.paddingButTop{
  padding: 0 1em 1em;
}
.padding1em3em{
  padding: 1em 3em;
}
.paddingLeft{
  padding-left: 1em;
}
.paddingRight{
  padding-right: 1em;
}
.paddingTop{
  padding-top: 1em;
}
.paddingBottom{
  padding-bottom: 1em;
}
.paddingHoriz{
  padding: 0 1em;
}
.bgWhite{
  background-color: white;
}
.bgGrey{
  background-color: #E8DDCD;
}
.bgLightGrey{
  background-color: #e9e9e9;
}
.disabled{
  pointer-events: none;
  opacity: 0.4;
}
.centerAlignText {
  text-align: center;
}
.leftAlignText {
  text-align: left;
  padding-top: 1em;
}
.yScroll{
  overflow-y: auto;
}
.smoothYscroll{
  overflow-y: auto;
  scroll-behavior: smooth;
}
.flexStartElem{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.colorRed {
  color: red;
}
.colorGreen {
  color: green;
}

.divider {
  width:100%;
  border-bottom: gray solid 1px;
}

.scrollList{
  max-height: 25em;
  overflow-y: auto;
}


/* HEADER */
header{
  background: #030;
  text-align: left;
  padding: 0 1em;
  display: flex;
  justify-content:space-between;
  /*box-shadow: 0px 0.3em 0.3em rgba(0, 0, 0, 0.4);*/
  border-bottom: 1px solid #444;
}
.hLogo{
  width:2em;
  height:2em;
}
.hBtn{
  cursor: pointer;
  background: #050;
}
.hBtn:hover {
  background-color: #0a0;
}
.hIcon {
  width:2em;
  height:2em;
  border-radius: 0.5em;
  background-repeat: no-repeat;
  background-size: 100%;
}
.hLogout{ background-image: url(/images/logout.svg); }
.hSync { background-image: url(/images/sync.svg); }
.hBlock {
  padding: 0em 0.5em;
  background: #050;
  color:#fff;
  overflow: hidden;
  border-radius: 0.5em;
  display: flex;
  align-items: center;
}
.data-state{
  width:6em;
}
.hTxt{
  color: #ddd;
  font-size: 70%;
  text-align: left;
}
.hFld{
  text-align: center;
  font-size: 90%;
  margin-top: -0.1em;
}

/* LoginView */
.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 64px 32px;
}

.lbl{
  color: #888;
  font-size: small;
}

.loginview {
  display: flex;
  height: 100%;
}

.loginimage {
  flex: 2;
}
.wsImage{
  height: 100%;
  width: 100%;
  object-fit: cover ;
}

.loginpanel {
  flex: 1;
  background-color: #fff;
  text-align: center;
}

.layout-map{
  background: #ada;
}

.inactive {
  color:gray;
}

.popover{
  padding: 0.5em;
  border: solid grey 1px;
  border-radius: 4px;
}

.colorDlgText{
  width: 7em;
}
.colorDlgInput{
  margin-left: 0.5em;
  height: 3em;
  cursor: pointer;
}
.opacityDlgText{
  padding-right: 0.5em;
  width: 4.5em;
}
.numberDlgText{
  width: 4.5em;
}
.stringDlgText{
  width: 20em;
}
.textDlgText{
  width: 20em;
  height: 8em;
}

/* LIST */
.tabsContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}
.listContainer {
  width: 100%;
  flex-grow: 1;
  background: white;
}
.listHeader {
  width: 100%;
  height: 30px;
  background-color: lightgray;
}
.listCell {
  border-bottom: solid 1px grey;
  overflow-y: hidden;
}
.listExpCell {
  border-bottom: solid 1px grey;
  overflow-y: auto;
}
.sortHeader {
  display: flex;
  align-items: center;
}
.cellContent {
  width:100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.listRowSelected {
  background: DarkSeaGreen;
}
.textCell {
  overflow: hidden;
  text-overflow: ellipsis;
}

.error{
  color:#b00;
}

.header {
  font-weight: bold;
  background-color: #bbb;
  padding: 0.2em 0em;
}

.detailsExp{
  min-height: 184px; /* expElHeight={185} -1 // height correction to show 1px bottom border*/
}

.categoryPrefGroup{
  background-color: white;
  margin: 0.5em;
  padding-top: 0.5em;
  border-radius: 5px;
  box-shadow: 2px 1px 5px 2px rgba(0, 0, 0, 0.4);
}
.categoryPrefItem{
  display: flex;
  align-items: center;
  padding: 0 0 0.5em 3em;
}
.categoryPrefIconText{
  font-size: 12px;
}
.categoryList {
  width:100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #e9e9e9;
}
.categoryHeader{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.3em 1em;
  background-color: #E8DDCD;
}
.categoryRow {
  text-align: left;
  border-bottom: solid grey 1px;
}
.categoryRow:hover {
  background-color: lightgray;
}

.featureExp {
  padding: 0 2em 0.5em 0;
  max-height: 20em;
  text-align: left;
  overflow-y: auto;
}
.featureTypeIcon {
  width: 1em;
  height: 1em;
}

.thumbExp{
  height:6em;
  margin: 0.2em 0em 0.2em 0.2em;
  cursor: pointer;
}
.thumbLoader{
  width: 8em;
  height:6em;
  margin: 0.2em 0em 0.2em 0.2em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.r_table  {width: 100%;}
.r_header {
  background: #ccc;
  height: 10em;
}
.r_body   {background: white;}
.r_perm   {
  font-size: small;
  writing-mode: vertical-rl;
  text-orientation: mixed;
}

/* PAGEs */
.rightPanHeader{
  background-color: white;
  padding: 0.3em 1em;
}
.btnsHeader{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.treeNodeLeftPart{
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 1em;
}


.page-kml .content{
  padding: 1em 0.5em;
}

.kml-selected{
  background-color:cyan;
}
.kml-selected .toolbar{
  margin: 1em 0em;
}
.kml-selected button{
  font-size: large;
  font-weight: bold;
}

.cameraExp{
  text-align: left;
  padding: 0.5em 0.6em 0 3em;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.pntType{
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  width: 62px;
  justify-content: center;;
  align-items:center;
}

.pntCatIcon{
  width: 2em;
  height: 2em;
  background-size: cover;
}
.pClk{
  cursor: pointer;
}

table.fullWidth {
  border-collapse: collapse;
  width:100%;
}

.leftAlignTable td {
  text-align: left;
}

.pntPostComm{
  display: flex;
  flex-direction: column;
  padding-top: 1em;
}

.usrPhotoCol{
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  width: 7em;
  padding: 0.5em;
  justify-content: space-around;
  align-items: center;
}

.usrPhoto{
  width: 6em;
  padding-bottom: 0.5em;
  cursor: pointer;
}

.expTable td {
  padding: 0 0.3em 0 0;
  font-size: 120%;
}

.expTbRow {
  background-color: lightgray;
}

table.expTbl td:first-child {
  width:6em;
}
table.expTbl td:last-child {
  font-weight: bold;
}

.prifileContent {
  padding: 1em;
}
.prifile1Row {
  display: flex;
  padding-bottom: 1em;
}
.prifile2Row {
  padding: 1em;
  text-align: right;
  background-color: lightgray;
  padding-bottom: 1em;
}
.prifile3Row {
  padding: 1em 3em;
  text-align: right;
}
.prifilePhoto{
  flex-grow: 0;
  flex-shrink: 0;
  width: 8em;
  height: 8em;
  border-radius: 4em;
  object-fit: cover;
}
.prifileName {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 250%;
}
.popupText{
  white-space: pre-wrap;
  text-align: left;
  max-height: 35em;
  overflow-y: auto;
}

.patrolExp{
  text-align: left;
  padding-left: 3em;
  padding-right: 2em;
}
.patrolExpData{
  display: flex;
  justify-content: space-between;
  padding: 1em 0;
}
.patrolPoints{
  display: flex;
  height: 11em;
  margin: 1em 3em 1em 0;
  box-shadow: 2px 1px 5px 2px rgba(0, 0, 0, 0.4);
}

.sensorTrackBox{
  display: flex;
  margin-top: 0.4em;
  padding-left: 1.5em;
  border-radius: 3px;
  box-shadow: 1px 0px 3px 1px rgba(0, 0, 0, 0.4);
}

.sensorTrackControls{
  display: flex;
  flex-direction: column;
  text-align: left;
}
.sensorColumn {
  text-transform: none;
}

.settingsItemTitle{
  text-align: left;
  width: 15em;
}
.settingsImgBtn{
  width: 150px;
  height: 100px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.settingsImgBtn:hover {
  background-color: #E8DDCD;
}
.settingsIconTitle{
  width: 11em;
  padding-left: 1em;
}
.settingsIconButton{
  width: 3em;
  padding-left: 1em;
}

.dataView {
  position: fixed;
  width: 25em;
  left:  0;
  bottom:0;
  background: #ddd;
  z-index: 100;
}
.dataHeader {
  font-weight: bold;
  padding: 0.2em 0;
}
.dataRow {
  display: flex;
  justify-content: space-between;
  padding: 0.1em 1em;
}
.dataValue {
  min-height: 1em;
  width: 15em;
  padding: 0.1em 0;
  background-color: white;
}

.changeLog{
  width: 35em;
  height: 40em;
}

.fog {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: black;
  z-index: 1000;
}

.visx-tooltip {
  z-index: 1400;
}

.MuiTreeItem-content {
  box-sizing: border-box;
}

.react-grid-item:not(.react-grid-placeholder) {
  background: #ccc;
  border: 1px solid black;
}

.widgetHandle {
  height: 20px;
  display: flex;
  justify-content: flex-end;
  cursor: move;
}

.widgetTitle {
  flex-grow: 1;
  text-align: center;
}

.widgetContent {
  flex-grow: 1;
  overflow: hidden;
}

.widgetButton {
  width: 1em;
  text-align: center;
  cursor: pointer;
}

.widgetsPanel {
  top: 4em;
  right: 1em;
  position: absolute;
  color: gray;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5em 1em;
  box-shadow: 1px 0px 3px 1px rgba(0, 0, 0, 0.4);
}

.widgetIcon {
  width: 2.5em;
  height: 2.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(150, 150, 150, 0.7);
  border: 1px solid grey;
  margin: 0.5em 0;
}

.mapWidget {
  position:relative;
  height: 100%;
}

.wdContainer {
  overflow-y:scroll;
  width:100%;
  height: 100%;
}

.wdContent {
  display: flex;
  min-width: 33em;
  width:100%;
  height: 100%;
}
